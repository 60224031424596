import PropTypes from 'prop-types';
import styled from 'styled-components';

import { convertTimestamp } from 'src/utils/timeHelpers';
import Tooltip from './Tooltip';

const EventLogWrapper = styled.div`
    padding: 4px 8px 2px;

    font-family: 'SF Mono', monospace;
    font-size: 14px;

    --eventLogTimeColor: var(--grey-3);
    --eventLogValueColor: var(--grey-1);
    --eventLogValueBGColor: ${({ $eventNameBgColor }) => ($eventNameBgColor ? `var(${$eventNameBgColor})` : 'var(--grey-8)')};

    @container (width < 315px) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
    }
`;

const EventLogTime = styled.span`
    display: inline-block;
    vertical-align: middle;
    margin: 0 8px 0 0;
    color: var(--eventLogTimeColor);
    &:before {
        content: '[';
    }
    &:after {
        content: ']';
    }

    @container (width < 315px) {
        display: none;
    }
`;

const EventLogValue = styled.span`
    display: inline-block;
    vertical-align: middle;
    padding: 2px 6px;
    border-radius: 4px;
    color: var(--eventLogValueColor);
    background: var(--eventLogValueBGColor);
`;

const TooltipIcon = styled.span`
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 8px;
    cursor: help;
`;

const EVENT_NAME_MAP = {
    pokiTrackingSdkStatusInitialized: { name: 'SDK initialized', bgColor: '--data-blue-1' },
    pokiTrackingScreenGameLoadingFinished: { name: 'Game loading finished', bgColor: '--data-blue-1' },
    pokiTrackingScreenGameplayStart: { name: 'Gameplay start', bgColor: '--data-green-1' },
    pokiTrackingScreenGameplayStop: { name: 'Gameplay stop', bgColor: '--data-green-1' },
    pokiTrackingScreenRewardedBreak: { name: 'Rewarded break', bgColor: '--data-orange-1' },
    pokiTrackingScreenCommercialBreak: { name: 'Commercial break', bgColor: '--data-orange-1' },
    pokiTrackingScreenDisplayAdRequest: { name: 'Display ad request', bgColor: '--data-orange-1' },
    pokiTrackingScreenDisplayAdDestroy: { name: 'Display ad destroy', bgColor: '--data-orange-1' },
    pokiTrackingMeasure: { name: 'Measure', bgColor: '--data-orange-1' },
};

// events that need to display additional data
const CUSTOM_EVENTS = ['pokiTrackingMeasure'];

export default function EventLog({ timestamp, eventName, eventData }) {
    const time = convertTimestamp(timestamp); // convert timestamp to [hh:mm:ss format]
    const event = EVENT_NAME_MAP[eventName]; // get event name and color from map

    // custom event might contain additional data and we need to display it
    const isCustomEvent = CUSTOM_EVENTS.includes(eventName) && eventData;

    const formattedEventData = {
        action: eventData?.action,
        category: eventData?.category,
        label: eventData?.label,
    };

    const customEventContent = isCustomEvent && (
        <pre>
            <code>{JSON.stringify(formattedEventData, null, 2)}</code>
        </pre>
    );

    return (
        event && (
            <EventLogWrapper $eventNameBgColor={event?.bgColor}>
                <EventLogTime>{time}</EventLogTime>
                <EventLogValue>
                    {event?.name || eventName}
                    {isCustomEvent && (
                        <Tooltip arrow content={customEventContent} placement="top">
                            <TooltipIcon>
                                ⓘ
                            </TooltipIcon>
                        </Tooltip>
                    )}
                </EventLogValue>
            </EventLogWrapper>
        )
    );
}

EventLog.propTypes = {
    timestamp: PropTypes.number.isRequired,
    eventName: PropTypes.string.isRequired,
    // we don't know what data will be passed to custom event
    // eslint-disable-next-line react/forbid-prop-types
    eventData: PropTypes.object,
};

EventLog.defaultProps = {
    eventData: null,
};
